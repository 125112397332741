<template>
    <div style="width: 100%;height: 100%;overflow: hidden">
        <div v-for="(item,index) in warehouse_img" :key="index">
            <img :src="item.url" class="lazyload">
        </div>
        <div style="display: flex">
            <div style="width: 100%;height: auto;overflow: hidden" >
                <div style="width: 50%; height: auto; overflow: hidden;float: left">
                    <div style="width: 100%; height: 100%;">
                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash11.jpeg" style="width: 100%; height: auto;">
                    </div>
                </div>
                <div class="old" style="width: 50%; height: 100%; background: #FFFFFF;float: left;padding-top: 50px;">
                    <Introductionlogin></Introductionlogin>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Introductionlogin from "./components/Introductionlogin";
export default {
    components: {
        Introductionlogin

    },

    computed: {

    },
    data () {
        return {
            warehouse_img:[
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash1.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash2.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash3.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash4.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash5.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash6.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash7.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash8.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash9.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/warehouse/Stash10.jpeg"},
            ]

        }
    },
    mounted:function(){


    },
    created: function(){
    
    },
    methods:{

    }
}
</script>

<style scoped>
.lazyload {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.old {
    width: 50%;
    height: 100%;
    background: #FFFFFF;
}
</style>
